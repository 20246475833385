import React, { useState, useEffect, useCallback } from 'react';
import PC from './pages/PC';
import Mobile from './pages/Mobile';
import { isMobile } from './utils/helper';
import debug from '@/utils/debug';

function App() {
  const [_isMobile, setIsMobile] = useState(isMobile());

  useEffect(() => {
    debug();
  }, []);

  const onResize = useCallback(() => {
    const mobileMedia = isMobile();
    console.log(mobileMedia ? 'mobile' : 'pc');
    setIsMobile(mobileMedia);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line
  }, []);

  return <div>{_isMobile ? <Mobile /> : <PC />}</div>;
}

export default App;
