import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import ScrollWheelHandler from 'react-scroll-wheel-handler';
import 'swiper/css/swiper.css';

import Page1 from './components/Page1';
import Page2 from './components/Page2';
import PaginationMask from './components/PaginationMask';
import AboutUs from './components/AboutUs';
import Supplier from '@/components/Supplier';

const Index = () => {
  const pages = [
    { hash: 'p1', component: <Page1 /> },
    { hash: 'p2', component: <Page2 /> },
    { hash: 'p3', component: <AboutUs /> },
    { hash: 'p4', component: <Supplier /> },
  ];

  const [mySwiper, setMySwiper] = useState(null);

  const swiperParams = {
    // 声明滑动方向
    direction: 'vertical',
    // 也可以叫“敏感度”，当滑动超过60px，才会触发显示下一页/上一页
    // 上下滑动的时候和页面滚动条（一页内的内容太多所以有 scroll）会有手势冲突，需要调整“敏感度”平衡这种冲突
    threshold: 500,
    // 当需要整屏滚动的时候，默认的 height 会导致容器计算错误，需要显示声明高度
    height: parseInt(window.innerHeight),
    // 设置散列导航选项，或true使用默认值。为每个slide增加散列导航（有点像锚链接）。
    hashNavigation: {
      replaceState: true,
    },
    getSwiper: obj => {
      if (!mySwiper) {
        setMySwiper({
          swiperRef: obj,
        });
      }
    },
  };

  const handleScroll = direction => {
    const _swiperRef = mySwiper.swiperRef;
    if (direction === 'up') {
      _swiperRef.slidePrev();
    } else {
      _swiperRef.slideNext();
    }
  };

  const handleClickNext = () => {
    mySwiper.swiperRef.slideNext();
  };

  return (
    <ScrollWheelHandler
      upHandler={() => handleScroll('up')}
      downHandler={() => handleScroll('down')}
    >
      <Swiper {...swiperParams}>
        {pages.map((item, index, arr) => (
          <div key={index} data-hash={item.hash}>
            {item.component}
            {index !== arr.length - 1 && <PaginationMask type="next" onClick={handleClickNext} />}
          </div>
        ))}
      </Swiper>
    </ScrollWheelHandler>
  );
};

export default Index;
