import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

import ArrowLight from '../../assets/arrow_down_light.png';
import ArrowDark from '../../assets/arrow_down_dark.png';

const Index = ({ type, onClick }) => {
  const style = type === 'prev' ? { top: 0, transform: 'rotate(180deg)' } : { bottom: 0 };

  return (
    <div className={styles.mask} style={style} onClick={onClick}>
      <img src={ArrowDark} className={styles.arrow} alt="arrow_dark" />
      <img src={ArrowLight} className={styles.arrow} alt="arrow_light" />
    </div>
  );
};

Index.prototype = {
  type: PropTypes.oneOf(['prev', 'next']),
};

Index.defaultProps = {
  type: 'next',
};

export default Index;
