// 判断是否为移动端
export const isMobile = () => {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return true;
  }
  return false;
};

// 修复在ios下键盘弹起后，用于解决收起键盘不下来的bug，在input onBlur时使用
// antd-mobile的input相关组件默认修复这个bug
export function IOSKeyboardFix() {
  if (!/iphone|ipod|ipad/i.test(navigator.appVersion)) {
    return;
  }
  // eslint-disable-next-line
  document.body.scrollTop = 0;
  window.pageXOffset = 0;
  document.documentElement.scrollTop = 0;
}

// 判断是否为iPhoneX
export const isIphoneX = () =>
  /iphone/gi.test(navigator.userAgent) &&
  window.screen &&
  window.screen.height === 812 &&
  window.screen.width === 375;

// 判断当前浏览器是否为微信浏览器
export function isWeixinBrowser() {
  return /micromessenger/.test(navigator.userAgent.toLowerCase());
}

// 跳转页面方法
export const navigateTo = url => {
  if (isWeixinBrowser()) {
    return window.location.replace(url);
  }
  window.open(url);
};

// 判断当前设备是否为安卓
export const isAndroid = () => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
  return isAndroid;
};
