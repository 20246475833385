import React from 'react';
import classNames from 'classnames';
import { DOWNLOAD_PAGE } from '@/utils/constant';

import styles from './style.module.scss';

import Logo from '../../assets/logo.png';
import BgRight from '../../assets/page_2_bg_right.png';

const Index = () => {
  const toDownloadPage = () => {
    window.open(DOWNLOAD_PAGE);
  };

  return (
    <div className={classNames(styles.pc_page, styles.bg)}>
      <img src={Logo} className={styles.logo} alt="logo" />
      <div className={styles.main}>
        <h1 className={styles.top_title}>精选爆款商品</h1>
        <h2 className={styles.sub_title}>品牌特卖厂家货源</h2>
        <p className={styles.desc}>自有品牌产地直供/拒绝中间商赚差价</p>
        <div className={styles.bar}></div>
        <div className={styles.download_btn} onClick={toDownloadPage}>
          立即下载APP
        </div>
      </div>
      <img src={BgRight} className={styles.bg_right} alt="bg_right" />
    </div>
  );
};

export default Index;
